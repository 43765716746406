.input-container {
    position: relative;
    margin: 10px 0;
}

input,
textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.label {
    position: absolute;
    top: 0;
    margin-top: 3%;
    left: 10px;
    padding: 10px;
    color: #888;
    pointer-events: none;
    transition: top 0.3s, left 0.3s, padding 0.3s, font-size 0.3s;
}

input:focus,
textarea:focus {
    outline: none;
    border-color: #007bff;
}

input:focus+label,
textarea:focus+label,
input:not(:placeholder-shown)+label,
textarea:not(:placeholder-shown)+label {
    top: -10px;
    left: 0;
    padding: 0 5px;
    font-size: 14px;
    background-color: #fff;
}

.button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}