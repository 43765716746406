.home {
  background-color: #eee8aa !important;
  padding: 10%;
}

.container {
  background-color: #eee8aa !important;
  display: flex;
  flex-direction: column;
}

.paragraph {
  margin: 10px;
  /* Add some spacing between paragraphs */
  text-align: start;
}

/* Media Query for Tablets and Larger */
@media screen and (min-width: 768px) {
  .container {
    background-color: #eee8aa !important;
    flex-direction: row;
    justify-content: space-between;
  }

  .paragraph {
    width: 48%;
    /* Adjust based on desired spacing */
  }
}

.typewriter h1 {
  width: fit-content;

  float: left;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em;
  /* Adjust as needed */
  animation:
    typing 3.5s steps(15, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: orange;
  }
}

.img_style {
  border-radius: 50%;
  border-width: thick;
  border-block: inherit;
  border: double;
  border-color: white;
  width: 100%;
  height: auto;
}

.responsive-text {
  font-size: 40px;
}

@media (max-width: 768px) {
  .responsive-text {
    font-size: 16px;
  }
}