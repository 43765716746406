.header {
    width: 100%;
    margin: auto;
    background-color: #eee8aa !important;
    position: relative;
    display: flex;
    height: 50px;

}

.header_name {
    float: left;
    margin: -1% 0% 1% 1%;
    width: 50%;
    text-align: justify;
}

.header_contact {
    padding: 20px;
    width: 50%;
    float: right;

}

.h1 {
    margin: 20px 0px 0px 10px;
}