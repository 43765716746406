.about_span {
  float: left;
  margin-left: 0%;
  background: #eee8aa !important;
  padding: 10px;
  margin-top: 1%;

}

.am {
  border-bottom: dashed;
  border-width: 4px;
  border-bottom-color: #eee8aa !important;
  font-weight: bold;
  font-family: serif;
  font-size: 35px;
  font-family: Poppins, sans-serif;
}

.description {
  font-size: 25px;
  font-family: serif;
  font-family: Poppins, sans-serif;
  line-height: 1.8;
}

.containerAbout {
  display: flex;
  flex-direction: column;
  margin: 0% 1% 0% 1%;
}

.paragraph {
  margin: 10px;
  /* Add some spacing between paragraphs */
  text-align: start;
}

.newparagraph {
  margin: 10px;
  /* Add some spacing between paragraphs */
  text-align: start;
}

.newparagraph2 {
  margin: 10px;
  /* Add some spacing between paragraphs */
  text-align: start;
}

/* Media Query for Tablets and Larger */
@media screen and (min-width: 768px) {
  .containerAbout {
    flex-direction: row;
    justify-content: space-between;
  }

  .paragraph {
    width: 48%;
    /* Adjust based on desired spacing */
  }

  .newparagraph {
    width: 48%;
    /* Adjust based on desired spacing */
  }
}

.circle {
  width: 200px;
  height: 200px;
  background-color: #eee8aa !important;
  ;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c4d4d;
  font-size: 200px;

  margin: 26% 15% 13% 30%;
}

.number {
  font-weight: bold;
}

.responsive-text {
  font-size: 40px;
}

@media (max-width: 768px) {
  .responsive-text {
    font-size: 26px;
  }
}